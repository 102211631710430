var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.data_student.alumno)+" "),_c('v-chip',{staticClass:"mx-12",attrs:{"outlined":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm.data_student.estado)+" ")])],1),_c('v-card-subtitle',[_vm._v(" Resumen de notas 2021 "),_c('br'),_vm._v(" "+_vm._s(_vm.data_student.grupo)+" ")]),(_vm.loaded)?_c('v-container',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v(" Area ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Curso ")]),_vm._l((_vm.periodos_academicos),function(item){return _c('th',{key:item.id,staticClass:"text-center"},[_vm._v(" "+_vm._s(item.denominacion)+" ")])})],2)]),_vm._l((_vm.notas),function(area){return _c('tbody',{key:area.idarea},[_c('tr',[_c('td',{attrs:{"rowspan":"10"}},[_vm._v(_vm._s(area.area))])]),_vm._l((area.cursos),function(curso){return _c('tr',{key:curso.idcurso},[_c('td',{staticClass:"linea-abajo"},[_vm._v(_vm._s(curso.curso))]),_vm._l((curso.calificaciones),function(nota,index){return _c('td',{key:index,class:[
                      'text-center',
                      'linea-abajo',
                      _vm.es_permitido(nota.idperiodo)
                        ? ''
                        : nota.nota > 10
                        ? 'aprobado'
                        : 'desaprobado' ]},[(!_vm.es_permitido(nota.idperiodo))?_c('div',[_vm._v(" "+_vm._s(nota.nota)+" ")]):_vm._e()])})],2)})],2)})]},proxy:true}],null,false,2074545442)})],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"10","md":"3"}},[_c('v-btn',{attrs:{"color":"primary","block":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(" Regresar ")])],1),_c('v-col',{attrs:{"cols":"10","md":"3"}},[_c('export-pdf',{attrs:{"idgrupo":_vm.data_student.idgrupo,"idstudent":_vm.data_student.id,"fullname":_vm.data_student.alumno}})],1)],1)],1):_c('div',[_c('v-progress-linear',{attrs:{"color":"deep-purple accent-4","indeterminate":"","rounded":"","height":"4"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }