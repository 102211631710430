<template>
  <div>
    <v-btn
      primary
      color="#1C0E0B"
      block
      dark
      :disabled="disabled && !loading_csv"
      @click="export_csv()"
    >
      Descargar como PDF
      <v-icon right dark>
        mdi-file-download
      </v-icon>
    </v-btn>
  </div>
</template>
<script>
import axios from "axios";
import api from "@/api.config.js";
export default {
  name: "ExportCsv",
  props: ["disabled", "idgrupo", "idstudent", "fullname"],
  data: () => ({
    loading_csv: false,
  }),
  methods: {
    get_config() {
      let tok = JSON.parse(localStorage.getItem("token-user"));
      let token = tok["token-user"];
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + token,
        },
        responseType: "blob",
      };
      return config;
    },
    export_csv() {
      this.loading_csv = true;
      axios
        .get(
          api.API_PATH +
            "/app/export-pdf/" +
            this.idstudent +
            "/" +
            this.idgrupo +
            "/",
          this.get_config()
        )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", this.fullname + ".pdf"); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  mounted() {},
};
</script>
