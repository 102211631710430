<template>
  <div>
    <v-card>
      <v-card-title>
        {{ data_student.alumno }}
        <v-chip class="mx-12" outlined color="primary">
          {{ data_student.estado }}
        </v-chip>
      </v-card-title>
      <v-card-subtitle>
        Resumen de notas 2021
        <br />
        {{ data_student.grupo }}
      </v-card-subtitle>

      <v-container v-if="loaded">
        <v-row justify="center">
          <v-col cols="12" md="8">
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Area
                    </th>
                    <th class="text-left">
                      Curso
                    </th>
                    <th
                      v-for="item in periodos_academicos"
                      :key="item.id"
                      class="text-center"
                    >
                      {{ item.denominacion }}
                    </th>
                    <!-- <th
                      class="text-left"
                      v-if="periodos_restringidos.length <= 0"
                    >
                      Promedio del Área
                    </th>-->
                  </tr>
                </thead>
                <tbody v-for="area in notas" :key="area.idarea">
                  <tr>
                    <td rowspan="10">{{ area.area }}</td>
                  </tr>
                  <tr v-for="curso in area.cursos" :key="curso.idcurso">
                    <td class="linea-abajo">{{ curso.curso }}</td>
                    <td
                      v-for="(nota, index) in curso.calificaciones"
                      :key="index"
                      :class="[
                        'text-center',
                        'linea-abajo',
                        es_permitido(nota.idperiodo)
                          ? ''
                          : nota.nota > 10
                          ? 'aprobado'
                          : 'desaprobado',
                      ]"
                    >
                      <div v-if="!es_permitido(nota.idperiodo)">
                        {{ nota.nota }}
                      </div>
                    </td>
                    <!--  <td
                      rowspan="10"
                      v-if="index == 0 && state_avg" index del curso
                      :class="[
                        'text-center',
                        'linea-abajo',
                        area.promedio > 10 ? 'aprobado' : 'desaprobado',
                      ]"
                    >
                      <div v-if="periodos_restringidos.length <= 0">
                        {{ Math.round(area.promedio) }}
                      </div>
                    </td>-->
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="10" md="3">
            <v-btn color="primary" block @click="$router.go(-1)">
              Regresar
            </v-btn>
          </v-col>
          <v-col cols="10" md="3">
            <export-pdf
              :idgrupo="data_student.idgrupo"
              :idstudent="data_student.id"
              :fullname="data_student.alumno"
            />
          </v-col>
        </v-row>
      </v-container>
      <div v-else>
        <v-progress-linear
          color="deep-purple accent-4"
          indeterminate
          rounded
          height="4"
        ></v-progress-linear>
      </div>
    </v-card>
  </div>
</template>
<script>
import axios from "axios";
import api from "../api.config";
import ExportPdf from "../components/ExportPdf.vue";
export default {
  components: {
    ExportPdf,
  },
  data: () => ({
    grupo_id: null,
    student_id: null,
    notas: [],
    state_avg: false,
    data_student: {},
    periodos_academicos: [],
    periodos_restringidos: [],
    loaded: false,
  }),
  methods: {
    get_config() {
      let tok = JSON.parse(localStorage.getItem("token-user"));
      let token = tok["token-user"];
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + token,
        },
      };
      return config;
    },
    get_periodo_academico() {
      let anio = 2021;
      axios
        .get(
          api.API_PATH + "/api/get-periodo-academico/" + anio + "/",
          this.get_config()
        )
        .then((r) => {
          this.periodos_academicos = r.data;
          //console.log("periodos",r.data)
          this.loaded = true;
          this.periodos_academicos.sort(function(a, b) {
            if (a.id > b.id) return 1;
            if (a.id < b.id) return -1;
            return 0;
          });
          this.get_notas();
          //console.log(this.arr_status_periodo_academico)
        })
        .catch((e) => {
          console.log(e);
        });
    },
    get_notas() {
      axios
        .get(
          api.API_PATH +
            "/api/notas-por-alumno-restringido/" +
            this.$route.params.idalumno +
            "/",
          this.get_config()
        )
        .then((r) => {
          //console.log(r);
          this.data_student = r.data;
          this.notas = r.data.notas;
          //this.periodos_restringidos = r.data.periodos_restringidos;
          this.notas.sort((a, b) => {
            if (a.idarea > b.idarea) return 1;
            if (a.idarea < b.idarea) return -1;
            return 0;
          });

          this.notas.forEach((e) => {
            e.cursos.sort((a, b) => {
              if (a.idcurso > b.idcurso) return 1;
              if (a.idcurso < b.idcurso) return -1;
              return 0;
            });
            e.cursos.forEach((element) => {
              element.calificaciones.sort((a, b) => {
                if (a.idperiodo > b.idperiodo) return 1;
                if (a.idperiodo < b.idperiodo) return -1;
                return 0;
              });
            });
          });
          this.loaded = true;
          setTimeout(() => {
            this.state_avg = true;
          }, 1000);
        });
    },
    es_permitido(idperiodo) {
      let res = this.periodos_restringidos.find(
        (e) => e.periodo_academico == idperiodo
      );
      return !!res;
    },
  },
  mounted() {
    this.get_periodo_academico();
  },
};
</script>
<style>
.linea-abajo {
  border-bottom: #e0e0e0 1px solid;
}
.aprobado {
  color: rgb(0, 225, 255);
}
.desaprobado {
  color: rgb(255, 89, 89);
}
</style>
